#login-container {
  padding: 0;
}

.note {
  color: grey;
}

#output {
  width: 100vh;
  height: 100vh;
}

#error-message-container {
  position: sticky;
  top: 0;
  left: 0;
}

.overlay {
  height: fit-content;
  width: fit-content;
  position: fixed;
  z-index: 101;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.2s;
  padding: 50px;
  display: none;
  max-height: 100%;
}

.overlay.open {
  display: block;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 2rem;
  display: block;
  transition: 0.3s;
}

.overlay, .overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .close-overlay {
  position: absolute;
  color: #818181;
  top: 0;
  right: 0;
}

.overlay .field {
  width: 100%;
  max-width: 300px;
  margin: auto;
}

.overlay .field input, .overlay .field .select {
  width: 100%;
}

.overlay .field input[type="checkbox"] {
  width: fit-content;
}

.overlay .field input[type="submit"] {
  margin-top: 20px;
  width: fit-content;
}

.overlay .field label {
  display: block;
  text-align: left;
  width: 100%;
}

.overlay .note {
  font-size: 11px;
  font-style: italic;
  max-width: 100%;
}

.overlay .w3-bar {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.overlay .w3-bar .selected {
  background-color: #ccc!important;
}

.overlay .w3-bar > * {
  flex-grow: 1;
}

.cancelled-row {
  text-decoration: line-through;
}

#batch-print-overlay {
  background-color: white;
  color: black;
}

#batch-print-overlay > .overlay-content {
  margin: 0;
  font-size: 20px;
}

.batch-tickets-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.batch-ticket-info {
  display: flex;
  flex-direction: column;
  font-size: 32px;
  justify-content: center;
}

.batch-ticket-container {
  display: flex;
}